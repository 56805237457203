<script lang="ts" setup>
import {
	COMPLETED_5_SURVEYS_MODAL_TITLE,
	COMPLETED_5_SURVEYS_MODAL_SUBTITLE,
	COMPLETED_5_SURVEYS_MODAL_MESSAGE,
	CHALLENGE_COMPLETED,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { computed } from 'vue';
import modalImage from '@/assets/icons/stack-of-money.svg';
import type { UserBalanceUpdateInfo } from '@/types';
import { useIncome } from '@/utils/composables/useIncome';
import { Pi2X } from '@primeinsightsgroupllc/prime-icons';
import { transactionsWithModalFooter } from '@/views/history/views/transactions/components/transactions';
import { useI18n } from 'vue-i18n';

const { options } = defineProps<{
	options: UserBalanceUpdateInfo;
}>();

const { t } = useI18n();
const { isMobile } = useScreenSize();

const { getTransformedValue, incomeAmount } = useIncome();

const doubleIncomeAmount = computed(() => {
	return `${getTransformedValue(+options.money_received! || 0)}`;
});

const doubleIncomeText = computed(
	() =>
		`<span style="text-decoration: line-through">${incomeAmount.value}</span> ${doubleIncomeAmount.value}`
);

const subtitleText = computed(() =>
	t(COMPLETED_5_SURVEYS_MODAL_SUBTITLE, {
		value: options.is_double_payout_challenge
			? doubleIncomeText.value
			: getTransformedValue(+options.money_received || 0),
	})
);

const messageText = computed(() =>
	t(COMPLETED_5_SURVEYS_MODAL_MESSAGE, {
		value: options.is_double_payout_challenge
			? doubleIncomeAmount.value
			: getTransformedValue(+options.money_received || 0),
	})
);
</script>

<template>
	<ModalCommonContent class="completed-5-surveys-modal text-center">
		<template #header>{{ $t(COMPLETED_5_SURVEYS_MODAL_TITLE) }} 💸</template>
		<template #icon>
			<img :src="modalImage" alt="" />
		</template>
		<template #title>
			<div v-if="options.is_double_payout_challenge" class="challenge-badge">
				<Pi2X />
				<PrimeText tag="span" size="xs">
					{{ $t(CHALLENGE_COMPLETED) }}
				</PrimeText>
			</div>
			<PrimeText
				tag="span"
				:size="isMobile ? 'base' : 'xl'"
				weight="500"
				v-html="subtitleText"
			/>
			💰
		</template>
		<template
			v-if="transactionsWithModalFooter.includes(options.transaction_type)"
			#message
		>
			<PrimeText
				tag="p"
				:size="isMobile ? 'sm' : 'base'"
				color="grey-700"
				v-html="messageText"
			/>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;

.completed-5-surveys-modal {
	@include breakpoint(mobile) {
		padding-bottom: 1.125rem;
	}

	:deep(.content-icon) {
		width: 12.5rem;
		height: 12.5rem;
	}

	.challenge-badge {
		width: max-content;
		margin: 0.5rem auto;
		justify-content: center;
		display: flex;
		align-items: center;
		padding: 0.25rem 0.5rem;
		background-color: var(--p-secondary-base);
		border-radius: var(--p-radius-rounded);

		& span {
			line-height: 1;
		}
	}
}
</style>
