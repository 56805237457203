import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkContextIdHook,
	checkHistoryTypeHook,
	checkInitializedClaim,
	showAdjoeOfferwall,
	checkFeatureFlagsHook,
} from '@/router/hooks';
import { FeatureFlags } from '@/enums';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: () => import('@/views/surveys/SurveysView.vue'),
		meta: {
			progress: true,
		},
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/offers',
		name: USER_ROUTE_NAME.OFFERS,
		component: () => import('@/views/offers/OffersView.vue'),
		meta: {
			feature: FeatureFlags.PS_OFFERS,
		},
		beforeEnter: checkFeatureFlagsHook,
	},
	{
		path: '/signature-login',
		name: USER_ROUTE_NAME.SIGNATURE_LOGIN,
		component: () => import('@/views/signature-login/SignatureLoginView.vue'),
		meta: {
			ignoreAuth: true,
		},
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: () => import('@/views/profile/ProfileView.vue'),
		meta: {
			progress: true,
		},
	},
	{
		path: '/reward-history/:type?',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: () => import('@/views/history/HistoryView.vue'),
		meta: {
			title: true,
		},
		beforeEnter: checkHistoryTypeHook,
	},
	{
		path: '/claim-reward',
		name: USER_ROUTE_NAME.CLAIM_REWARD,
		component: () => import('@/views/confirm-claim/ConfirmClaimView.vue'),
		meta: {
			title: true,
		},
		beforeEnter: checkInitializedClaim,
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: () => import('@/views/support/SupportView.vue'),
		meta: {
			progress: true,
		},
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		component: () =>
			import('@/views/email-subscriptions/EmailSubscriptionsView.vue'),
		meta: {
			title: true,
			ignoreAuth: true,
		},
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: () => import('@/views/referrals/ReferralsView.vue'),
		meta: {
			progress: true,
		},
	},
	{
		path: '/games',
		name: USER_ROUTE_NAME.GAMES,
		component: () => import('@/views/games/GamesView.vue'),
		beforeEnter: showAdjoeOfferwall,
		meta: {
			progress: true,
		},
	},
	{
		path: '/offers',
		name: USER_ROUTE_NAME.OFFERS,
		component: () => import('@/views/offers/OffersView.vue'),
		meta: {
			progress: true,
		},
	},
	{
		path: '/recent-activity-offers',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
		component: () =>
			import('@/views/recent-activity-offers/RecentActivityOffersView.vue'),
		meta: {
			title: true,
		},
	},
];
