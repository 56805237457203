import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
	ACCOUNT,
	GAMES,
	HELP,
	SURVEYS,
	OFFERS,
	MORE,
	REFER_FRIEND,
} from '@/constants/locales';
import type { NavItem } from '@/types';
import {
	PiGameController,
	PiGameControllerFilled,
	PiHome,
	PiHomeFill,
	PiQuestionCircle,
	PiQuestionCircleFill,
	PiHcAccount,
	PiHcAccountfilled,
	PiCrown,
	PiCrownFill,
	PiDotsThreeCircle,
	PiUsersGroupAdd,
	PiUsersGroupAddFill,
} from '@primeinsightsgroupllc/prime-icons';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { REWARDS_MODAL } from '@/constants/modals';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavItems = (isMobile = false) => {
	const { isTablet, isMobileApp } = useMobileApp();
	const { isOfferwallsAvailable, isOfferwallsPromo, isPsOffersEnabled } =
		storeToRefs(useUserStore());
	const { isAdjoeEnabled } = storeToRefs(useAppStore());
	const { t } = useI18n();
	const route = useRoute();
	const router = useRouter();

	const activeItemId = ref<string>('');
	const isOpen = ref<boolean | string>(false);

	const setMobileIcon = (item: NavItem) => {
		if (activeItemId.value === item.id) return item.iconActive;
		return item.icon;
	};

	const navigationClick = (item: NavItem) => {
		if (item.to) {
			router.push({ name: item.to });
			return;
		}
		isOpen.value = isOpen.value === item.id ? false : item.id;
	};

	const isActive = (item: NavItem) => {
		return Boolean(
			activeItemId.value === item.id ||
				item.children?.some((subItem) => subItem.id === activeItemId.value)
		);
	};

	const setActiveItemId = (routeName: string | symbol): void => {
		activeItemId.value =
			[...items.value, ...moreItems.value].find(
				(navItem: NavItem) => navItem.to === routeName
			)?.id || '';
	};

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items
			.filter((item) => item.placed?.includes(type) && !item.disabled)
			.map((item) => ({
				...item,
				children: item.children ? filterItems(item.children, type) : undefined,
				icon: isMobile ? setMobileIcon(item) : item.icon,
				active: isActive(item),
			}));
	};

	const moreItems = computed<Array<NavItem>>(() => [
		{
			icon: PiHcAccount,
			iconActive: PiHcAccountfilled,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			active: false,
			disabled: false,
			id: 'profile',
			placed: ['mobile'],
		},
		{
			icon: PiQuestionCircle,
			iconActive: PiQuestionCircleFill,
			label: t(HELP),
			to: USER_ROUTE_NAME.SUPPORT,
			active: false,
			disabled: false,
			id: 'support',
			placed: ['mobile'],
		},
		{
			icon: PiUsersGroupAdd,
			iconActive: PiUsersGroupAddFill,
			label: t(REFER_FRIEND),
			to: USER_ROUTE_NAME.REFERRALS,
			active: false,
			disabled: false,
			id: 'referrals',
			placed: ['mobile'],
		},
	]);

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiHome,
			iconActive: PiHomeFill,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			active: false,
			disabled: false,
			id: 'surveys',
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiCrown,
			iconActive: PiCrownFill,
			label: t(OFFERS),
			to: USER_ROUTE_NAME.OFFERS,
			active: false,
			disabled: !isPsOffersEnabled.value,
			id: 'offers',
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiGameController,
			iconActive: PiGameControllerFilled,
			label: t(GAMES),
			to: USER_ROUTE_NAME.GAMES,
			active: false,
			disabled:
				!(isMobileApp && !isTablet()) ||
				!isOfferwallsAvailable.value ||
				!isAdjoeEnabled.value,
			id: 'games',
			...(isOfferwallsPromo.value && {
				badge: {
					text: isOfferwallsPromo.value,
					textColor: '#ffffff',
					backgroundColor: '#E76F51',
				},
			}),
			placed: ['mobile'],
		},
		{
			icon: PiHcAccount,
			iconActive: PiHcAccountfilled,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			active: false,
			disabled: false,
			id: 'profile',
			placed: ['desktop'],
		},
		{
			icon: PiQuestionCircle,
			iconActive: PiQuestionCircleFill,
			label: t(HELP),
			to: USER_ROUTE_NAME.SUPPORT,
			active: false,
			disabled: false,
			id: 'support',
			placed: ['desktop'],
		},
		{
			icon: PiDotsThreeCircle,
			label: t(MORE),
			isActive: true,
			id: 'more',
			placed: ['mobile'],
			children: moreItems.value,
		},
	]);

	const isChildRoute = computed(() =>
		[...items.value, ...moreItems.value]
			.find((item: NavItem) => item.id === isOpen.value)
			?.children?.find(
				(subItem: NavItem) =>
					subItem.id === route.name && subItem.placed.includes('desktop')
			)
	);

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	onMounted(async () => {
		await router.isReady();
		route?.name ? setActiveItemId(route?.name) : '';
	});

	watch(
		() => route.name,
		async (newVal) => {
			if (!isChildRoute.value) {
				isOpen.value = false;
			}
			if (!newVal) return;
			await router.isReady();
			setActiveItemId(newVal);
		},
		{ immediate: true }
	);

	return {
		desktopItems,
		mobileItems,
		navigationClick,
		isOpen,
	};
};

export const useMainHeaderActions = () => {
	const { createNewModal } = useModalStorage();

	const openRewardsModal = async () => {
		await createNewModal(REWARDS_MODAL, { position: 'bottom' });
	};

	return {
		openRewardsModal,
	};
};
