<script setup lang="ts">
import { computed, watch } from 'vue';
import { useNavItems } from '@/components/common/header/composables';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useWindowScroll } from '@vueuse/core';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Capacitor } from '@capacitor/core';
import {
	PrimeMobileNavigationWrapper,
	PrimeNavigationMobile,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { Platforms } from '@/enums';
import type { NavItem } from '@/types';
import { PrimeNavigationWrapperSizes } from '@/enums';
import { MORE } from '@/constants/locales';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { mobileItems, navigationClick, isOpen } = useNavItems(true);
const { y: axisY } = useWindowScroll();
const authStore = useAuthStore();
const { isMobileNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();

watch(
	axisY,
	(newValue, oldValue) => {
		// show mobile nav when scrolling on top direction
		if (oldValue && newValue < oldValue) {
			!isMobileNavVisible.value &&
				authStore.setMobileNavigationVisibility(true);
		} else {
			// 64 -- height of the mobile nav
			if (newValue > 80) {
				// hide mobile nav when offset from top position is more than 64px
				isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(false);
			} else {
				// show mobile nav when offset from top position is less than 64px
				!isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(true);
			}
		}
	},
	{ immediate: true }
);

const platform = computed<Platforms>(
	() => Capacitor.getPlatform() as Platforms
);
</script>

<template>
	<PrimeMobileNavigationWrapper
		class="mob-navigation"
		:size="PrimeNavigationWrapperSizes.Large"
		:data="mobileItems"
		:platform="platform"
		:is-mobile-app="isMobileApp"
		:collapsed="!isMobileNavVisible"
	>
		<template #items="{ data }">
			<PrimeNavigationMobile
				v-for="item in data"
				:key="item.id"
				:data="{ ...item, isOpen, modalTitle: t(MORE) }"
				@navigation-click="navigationClick($event as NavItem)"
				@on-modal-close="isOpen = false"
			/>
		</template>
	</PrimeMobileNavigationWrapper>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;
:deep(.p-app-mobile-nav__wrapper) {
	background-color: var(--p-grey-50);
}

.mob-navigation:deep(.p-app-mobile-nav) {
	position: relative;
	color: var(--p-grey-800);
	gap: 0.5rem;

	span {
		font-weight: 500;
		font-size: 0.875rem;
	}

	svg {
		display: block;
		position: relative;
		margin: 0.25rem 0;
		color: var(--p-grey-800);
	}

	&.p-is-active {
		color: var(--p-grey-800) !important;
		&::before {
			opacity: 1;
		}
		svg {
			color: var(--p-text-default);
		}
	}

	& .p-nav-badge {
		top: -15%;
	}

	&::before {
		content: '';
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 4rem;
		height: 2rem;
		background-color: rgba(var(--p-secondary-base-rgb), 0.4);
		border-radius: 2rem;
		transition: 0.2s ease-in-out;
		opacity: 0;
	}
}
</style>
